import { startTransition, useMemo, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import {
  ParsedProductDetailsAttribute,
  ParsedProductsApiResponse,
  ProductDetailsApiDocument,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import { RELATED_PRODUCTS_PAGE_SIZE } from '@hultafors/eripiowear/api';
import { useGlobal } from '@hultafors/eripiowear/hooks';
import {
  EripiowearProduct,
  EripiowearProductDetails,
  ProductDetailPageFragment,
} from '@hultafors/eripiowear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { ImageAttribute } from '../image-attribute/image-attribute';
import { List } from '../list/list';
import { Paragraph } from '../paragraph/paragraph';
import { ProductColumns } from '../product-columns/product-columns';
import { ProductHero } from '../product-hero/product-hero';
import { Products } from '../products/products';
import { ReadMoreLink } from '../read-more-link/read-more-link';
import { RelatedProducts } from '../related-products/related-products';
import { Section } from '../section/section';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);
const BynderVideo = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.BynderVideo),
);

interface ProductPageContentProps {
  product: EripiowearProductDetails;
  initialColor: ProductDetailsValue;
  content: ProductDetailPageFragment;
}

export const ProductPageContent: React.FC<ProductPageContentProps> = ({
  product,
  initialColor,
  content,
}) => {
  const { globalContent } = useGlobal();
  const [color, setColor] = useState<ProductDetailsValue>(initialColor);
  const [size, setSize] = useState<string | undefined>();

  const images = useMemo(() => {
    return product.images || [];
  }, [product]);

  const { locale } = useRouter();
  const { data: relatedProducts, isValidating: relatedProductsLoading } =
    useSWR<ParsedProductsApiResponse<EripiowearProduct>>(
      product.id &&
        product.related &&
        `/api/productrelationlist/${product.related}?${new URLSearchParams({
          pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
          productId: `${product.id}`,
          ...(locale && locale !== 'com' ? { locale } : {}),
        }).toString()}`,
      {
        refreshWhenHidden: false,
        revalidateOnFocus: false,
        revalidateOnMount: true,
      },
    );
  const { data: similarProducts, isValidating: similarProductsLoading } =
    useSWR<ParsedProductsApiResponse<EripiowearProduct>>(
      product.id &&
        product.related &&
        `/api/productrelationlist/${product.similar}?${new URLSearchParams({
          pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
          productId: `${product.id}`,
          ...(locale && locale !== 'com' ? { locale } : {}),
        }).toString()}`,
      {
        revalidateOnMount: true,
      },
    );

  const documentsMapper = (item: ProductDetailsApiDocument, index: number) => {
    return (
      <ImageAttribute
        key={`Doc-${index}`}
        title={item.name}
        attributeName="documents"
        document={{
          filetype: item.filetype,
          name: item.name,
          size: item.size,
          url: item.url,
        }}
      />
    );
  };

  const sustainabilityMapper = (item: string, index: number) => (
    <ImageAttribute
      key={`Sustainability-${index}`}
      title=""
      attributes={item}
      attributeName="Sustainability"
    />
  );

  const certificationsMapper = (
    certification: ParsedProductDetailsAttribute,
    index: number,
  ) => {
    return (
      <ImageAttribute
        key={`Cert-${index}`}
        title={certification.name || ''}
        attributes={certification.values}
        attributeName={content.certifications || ''}
        image={certification.image}
      />
    );
  };

  const selectSize = (item: string) => {
    startTransition(() => {
      setSize(item);
    });
  };

  if (!product || !content || !globalContent) {
    return null;
  }
  return (
    <>
      <ProductHero
        images={images}
        product={product}
        badgeText={globalContent?.new || ''}
        rrpLabel={globalContent.msrp || ''}
        rrpIncludingVatLabel={globalContent.msrpIncludingVat || ''}
        setColor={setColor}
        color={color}
        sizesLabel={content?.sizes || ''}
        colorsLabel={content?.colors || ''}
        colorAlt={content?.colorSwatch ?? ''}
      />
      {/* <OptionsBar
        sizeSelectorLabel={size ?? (content.chooseSize || '')}
        sizeChartLabel={content.sizeChartLabel || ''}
        isOneSize={product.isOneSize}
        oneSizeLabel={content.oneSize}
        sizeChartPdfUrl={content.sizeChartPdf?.url}
        selectedSize={size}
        selectSize={selectSize}
        chooseSizeLabel={content.chooseSize || ''}
        productSizes={product.sizes}
        sku={product.sku}
        similarProducts={similarProducts?.items || []}
      /> */}
      <ProductColumns>
        <div>
          {product.technical_description && (
            <Paragraph className="ProductTechnicalDescription">
              {product.technical_description}
            </Paragraph>
          )}
          <List list={product.features} />
        </div>
        <div>
          <Accordion allowZeroExpanded>
            {!!product.certifications?.length && (
              <AccordionItem label={content.certifications || ''}>
                {product.certifications.map(certificationsMapper)}

                {content.certificationsLink?.slug && (
                  <ReadMoreLink href={content.certificationsLink.slug}>
                    {content.certificationsLinkLabel ??
                      content.certificationsLink.slug}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {product.washing && (
              <AccordionItem label={content.care ?? ''}>
                <ImageAttribute
                  title=""
                  attributes={product.washing.values}
                  attributeName={content.care ?? ''}
                />
                {content.careLink?.slug && (
                  <ReadMoreLink href={content.careLink.slug}>
                    {content.careLinkLabel ?? content.careLink.slug}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {product.materials && (
              <AccordionItem label={content.materials}>
                <Paragraph className="MaterialsText">
                  {product.materials}
                </Paragraph>
                {!!product.cobranding?.values?.length && (
                  <ImageAttribute
                    title=""
                    attributes={product.cobranding.values}
                    attributeName="Cobranding"
                  />
                )}
                {content.materialsLink?.slug && (
                  <ReadMoreLink href={content.materialsLink.slug}>
                    {content.materialsLinkLabel ?? content.materialsLink.slug}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {!!product.documents?.length && (
              <AccordionItem label={content.documents}>
                {product.documents.map(documentsMapper)}
                {content.documentsLink?.slug && (
                  <ReadMoreLink href={content.documentsLink.slug}>
                    {content.documentsLinkLabel ?? content.documentsLink.slug}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
            {!!product.sustainability?.length && (
              <AccordionItem label={content.sustainability}>
                {product.sustainability.map(sustainabilityMapper)}

                {content.sustainabilityLink?.slug && (
                  <ReadMoreLink href={content.sustainabilityLink.slug}>
                    {content.sustainabilityLinkLabel ??
                      content.sustainabilityLink.slug}
                  </ReadMoreLink>
                )}
              </AccordionItem>
            )}
          </Accordion>
        </div>
      </ProductColumns>
      {product?.productvideo && !product.productvideo.includes('https') && (
        <Section zeroTop largePaddingBottomDesktop>
          <Grid
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'desktop', columnGap: 30 },
            ]}
          >
            <GridChild>
              <BynderVideo
                videoId={product.productvideo}
                style={{
                  inlineSize: '90%',
                  marginInline: 'auto',
                  maxInlineSize: '800px',
                }}
              />
            </GridChild>
          </Grid>
        </Section>
      )}
      {product.related &&
        relatedProducts?.items &&
        relatedProducts.items.length > 0 && (
          <Section noTop borderTopDesktop largePaddingTop>
            <RelatedProducts header={content.relatedProducts || ''}>
              <Grid
                columnGap={[
                  { columnGap: 15 },
                  { breakpoint: 'desktop', columnGap: 30 },
                ]}
                columns={[
                  { columns: 1 },
                  { breakpoint: 'mediumMobile', columns: 2 },
                  { breakpoint: 'desktop', columns: 4 },
                ]}
              >
                <Products
                  products={relatedProducts?.items}
                  badgeText={globalContent?.new || ''}
                  isLoading={relatedProductsLoading}
                  numberOfItems={RELATED_PRODUCTS_PAGE_SIZE}
                />
              </Grid>
            </RelatedProducts>
          </Section>
        )}
      {product.similar &&
        similarProducts?.items &&
        similarProducts.items.length > 0 && (
          <Section noTop borderTopDesktop largePaddingTop>
            <RelatedProducts header={content.similarProducts || ''}>
              <Grid
                columnGap={[
                  { columnGap: 15 },
                  { breakpoint: 'desktop', columnGap: 30 },
                ]}
                columns={[
                  { columns: 1 },
                  { breakpoint: 'mediumMobile', columns: 2 },
                  { breakpoint: 'desktop', columns: 4 },
                ]}
              >
                <Products
                  products={similarProducts.items}
                  badgeText={globalContent?.new || ''}
                  isLoading={similarProductsLoading}
                  numberOfItems={RELATED_PRODUCTS_PAGE_SIZE}
                />
              </Grid>
            </RelatedProducts>
          </Section>
        )}
    </>
  );
};
