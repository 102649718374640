import Image from 'next/image';

import { ProductDetailsApiDocument } from '@hultafors/shared/types';

import { ImageFragment } from '@hultafors/eripiowear/types';

import { H4 } from '../h4/h4';
import { Paragraph } from '../paragraph/paragraph';

import FileSvg from './file.svg';
import { ImageAttributeStyled } from './image-attribute.styled';

export interface ImageAttributeProps {
  title: string;
  attributes?: any[] | string;
  pageUrl?: string;
  attributeName: string;
  image?: ImageFragment;
  document?: ProductDetailsApiDocument;
}

export const ImageAttribute: React.FC<ImageAttributeProps> = ({
  attributes = [],
  attributeName,
  title,
  image,
  document,
  pageUrl,
}) => {
  return (
    <ImageAttributeStyled>
      {attributeName === 'documents' ? (
        <div className="DocumentWrapper">
          <div className="ImgWrapper">
            <FileSvg
              aria-hidden={true}
              focusable={false}
              width={24}
              height={24}
            />
          </div>
          <Paragraph>
            <a href={document?.url || ''} download>
              {document?.name}
            </a>{' '}
            ({document?.size})
          </Paragraph>
        </div>
      ) : (
        title && (
          <H4 className={attributeName + ' Title'}>
            {image?.responsiveImage ? (
              <Image
                src={image?.responsiveImage?.src || ''}
                alt={title}
                width={24}
                height={24}
                blurDataURL={image.responsiveImage?.base64 || ''}
                placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
              />
            ) : (
              <Image
                src={image?.url || ''}
                alt={title}
                width={24}
                height={24}
              />
            )}
            {title}
          </H4>
        )
      )}

      {attributes && typeof attributes === 'string' ? (
        <div className={attributeName + ' Attribute'}>
          <Paragraph>{attributes}</Paragraph>
        </div>
      ) : (
        attributes &&
        Array.isArray(attributes) &&
        attributes.length > 0 &&
        attributes.map((attr: any, i) => {
          return (
            <div
              key={`${attributeName}-${i}`}
              className={attributeName + ' Attribute'}
            >
              {attr.image && (
                <div className="ImgWrapper">
                  <img src={`${attr.image.url}`} alt={attr.text} height={24} />
                </div>
              )}
              <Paragraph>{attr.text}</Paragraph>
            </div>
          );
        })
      )}
    </ImageAttributeStyled>
  );
};
