import { ListStyled } from './list.styled';

export interface ListProps {
  list?: React.ReactNode[];
}

export const List: React.FC<ListProps> = ({ list = [] }) => {
  return list?.length ? (
    <ListStyled>
      <ul>
        {list.map((item, index) => (
          <li key={`Feature-${index}`}>{item}</li>
        ))}
      </ul>
    </ListStyled>
  ) : null;
};
