import Image from 'next/image';

import { useGlobal } from '@hultafors/eripiowear/hooks';
import {
  IconLinkFragment,
  LinkFragment,
  PageLinkFragment,
} from '@hultafors/eripiowear/types';

import LogoSvg from '../../svg/eripiowear_standing.svg';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Markdown } from '../markdown/markdown';
import { Paragraph } from '../paragraph/paragraph';
import { Section } from '../section/section';
import { SubscribeArea } from '../subscribe-area/subscribe-area';

import {
  BottomSection,
  ContactLinkRow,
  Content,
  CookieSettings,
  FooterFunctions,
  FooterLink,
  FooterStyled,
  FooterTop,
  IntroText,
  LinkList,
  LinkListItem,
  Logo,
  MiddleSection,
  PartnerLoginLink,
  SocialMedia,
  SocialMediaLink,
  SubscribeAreaWrapper,
} from './footer.styled';
import LoginSvg from './login-link-white.svg';

export const Footer: React.FC = () => {
  const { footer, globalContent, settings } = useGlobal();
  const datoLogo = footer?.footerLogo?.url;

  if (!footer) {
    return null;
  }

  const handleOnPressSettings = () => {
    // TODO fix TS error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  const socialMediaMapper = ({ id, url, icon }: IconLinkFragment) => {
    if (url) {
      const temp = new URL(url).hostname.match(/(\w+\.)?(.+)\.('co\.uk'|\w+)/);
      const length = temp?.length || 0;
      const match = temp?.[length - 2] || '';
      const alt = `${match[0]?.toUpperCase() || ''}${match.substring(1)}`;

      return (
        <SocialMediaLink
          key={`SocialMediaLink-${id}`}
          href={url || ''}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={alt}
        >
          <Image
            src={icon?.url || ''}
            alt={alt}
            aria-hidden={true}
            width={40}
            height={40}
            sizes="40px"
          />
        </SocialMediaLink>
      );
    } else {
      return null;
    }
  };

  function menuMapper(item: PageLinkFragment) {
    if (!item.slug) {
      return null;
    }
    return (
      <LinkListItem key={`FooterLink-${item.id}`}>
        <FooterLink href={item.slug}>
          {item.shortTitle ?? item.title ?? item.slug}
        </FooterLink>
      </LinkListItem>
    );
  }

  const brandLinkMapper = (item: LinkFragment) => {
    if (!item.url) {
      return null;
    }
    return (
      <LinkListItem key={`FooterLink-${item.id}`}>
        <FooterLink href={item.url} target="_blank">
          {item.label ?? item.url}
        </FooterLink>
      </LinkListItem>
    );
  };

  return (
    <FooterStyled>
      <Section>
        <Grid>
          <GridChild>
            <FooterTop>
              <Logo>
                {datoLogo ? (
                  <Image
                    alt="Eripiowear logotype"
                    src={datoLogo}
                    fill
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  <LogoSvg width={80} height={74} title="Eripiowear logotype" />
                )}
              </Logo>

              <FooterFunctions>
                {globalContent.partnerPortalUrl &&
                  globalContent.partnerPortalLabel && (
                    <Paragraph>
                      <PartnerLoginLink
                        href={globalContent.partnerPortalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {globalContent.partnerPortalLabel}
                        <LoginSvg
                          aria-hidden={true}
                          focusable={false}
                          width={16}
                          height={16}
                        />
                      </PartnerLoginLink>
                    </Paragraph>
                  )}
              </FooterFunctions>
            </FooterTop>

            <MiddleSection id="footer-middle-section">
              <Content>
                {footer.brandsIntro && (
                  <IntroText>
                    <Markdown>{footer.brandsIntro}</Markdown>
                  </IntroText>
                )}
                <LinkList>{footer.brandLinks.map(brandLinkMapper)}</LinkList>

                {footer.contact && (
                  <ContactLinkRow>
                    <Markdown>{footer.contact}</Markdown>
                  </ContactLinkRow>
                )}
              </Content>
              <FooterFunctions />
              {footer.dotDigitalFormUrl && (
                <SubscribeAreaWrapper>
                  <SubscribeArea
                    subscribeInfoText={footer.subscribeInfoText || ''}
                    subscribeButtonText={footer.subscribeButtonLabel || ''}
                    userEmailFormUrl={footer.dotDigitalFormUrl}
                    userEmailFormImage={footer.dotDigitalImage}
                  />
                </SubscribeAreaWrapper>
              )}
            </MiddleSection>
            <BottomSection>
              <LinkList>
                {footer.menu?.map(menuMapper)}
                {footer.cookieSettings && (
                  <LinkListItem>
                    <CookieSettings onClick={handleOnPressSettings}>
                      {footer.cookieSettings}
                    </CookieSettings>
                  </LinkListItem>
                )}
              </LinkList>

              <SocialMedia>
                {footer.socialMedia?.map(socialMediaMapper)}
              </SocialMedia>
            </BottomSection>
          </GridChild>
        </Grid>
      </Section>
    </FooterStyled>
  );
};
