import { ProductDetailsValue } from '@hultafors/shared/types';

import { ProductColor } from '../product-color/product-color';

import {
  ProductColorsPreviewStyled,
  RemainingColors,
} from './product-colors-preview.styled';

export interface ProductColorsPreviewProps {
  limit: number;
  colors?: ProductDetailsValue[];
}

export const ProductColorsPreview: React.FC<ProductColorsPreviewProps> = ({
  limit = 6,
  colors = [],
}) => {
  if (!Array.isArray(colors) || !colors.length) {
    return null;
  }

  const limitedColors = limit ? colors.slice(0, limit) : colors;
  const remaining = limit ? colors.slice(limit).length : null;

  return (
    <ProductColorsPreviewStyled>
      {limitedColors.map((color) => (
        <ProductColor
          color={color}
          productColorImageSize={12}
          key={`ProductColorsPreview-${color.text}`}
        />
      ))}
      {remaining ? <RemainingColors>+{remaining}</RemainingColors> : null}
    </ProductColorsPreviewStyled>
  );
};
