import Link from 'next/link';
import styled, { css } from 'styled-components';

import { colors, fontWeights } from '@hultafors/eripiowear/helpers';

export const ProductListItemLink = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 96px;
`;

export const ProductListItemStyled = styled.article`
  display: flex;
  max-height: 150px;
  width: 100%;
  padding: 20px 0;
  border-bottom: thin solid ${colors.gray4};

  span {
    inline-size: 100%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  left: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  svg circle {
    fill: ${colors.black};
    transition: all 0.2s;
  }

  &:hover {
    svg circle {
      fill: ${colors.gray1};
      transition: all 0.2s;
    }
  }
`;

interface NarrowProps {
  $narrow?: boolean;
}

export const ProductImage = styled.div<NarrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  background: ${colors.gray5};
  ${({ $narrow }) =>
    $narrow &&
    css`
      max-width: 200px;
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    mix-blend-mode: multiply;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 15px;
`;

export const ProductName = styled.span`
  display: block;
  font-size: 16px;
  font-weight: ${fontWeights.normal};
  line-height: 20px;
  margin-block-end: 0.65rem;
`;

export const ProductSize = styled.span`
  font-size: 14px;
  font-weight: ${fontWeights.normal};
  margin-block-end: 0.65rem;
`;

export const ProductColor = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductColorImage = styled.img`
  border-radius: 50%;
  margin-inline-end: 10px;
  width: 17px;
  height: 17px;
`;

export const ProductColorLabel = styled.span`
  font-size: 14px;
  line-height: 17px;
`;

export const ProductDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-block-end: 0.65rem;
  color: ${colors.black};
`;

export const ProductDetailsItem = styled.span`
  font-size: 12px;
  font-weight: ${fontWeights.normal};
  color: ${colors.gray2};
  letter-spacing: 0.5px;

  & + & {
    margin-inline-start: 10px;
  }
`;
