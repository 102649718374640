import dynamic from 'next/dynamic';

import {
  ProductDetailsApiImage,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import { useFavorites } from '@hultafors/eripiowear/hooks';
import { EripiowearProductDetails } from '@hultafors/eripiowear/types';

import { Badge } from '../badge/badge';
import { Favorite } from '../favorite/favorite';
import { ProductDetails } from '../product-details/product-details';
import { ProductName } from '../product-name/product-name';
import { ProductPrice } from '../product-price/product-price';

import {
  Carousel,
  InfoContent,
  InfoTab,
  InfoWrapper,
  Inner,
  ProductCertificates,
  ProductHeroInfoText,
  ProductHeroStyled,
  TraitImage,
  TraitItem,
  TraitLabel,
  TraitList,
} from './product-hero.styled';

const ProductImageCarousel = dynamic(() =>
  import('../product-image-carousel/product-image-carousel').then(
    (mod) => mod.ProductImageCarousel,
  ),
);

export interface ProductHeroProps {
  images?: ProductDetailsApiImage[];
  product: EripiowearProductDetails;
  badgeText?: string;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  setColor(color: ProductDetailsValue): void;
  color: ProductDetailsValue;
  sizesLabel?: string;
  colorsLabel?: string;
  colorAlt?: string;
}

export const ProductHero: React.FC<ProductHeroProps> = ({
  images,
  product,
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  // setColor,
  // color,
  sizesLabel = '',
  colorsLabel = '',
  colorAlt = '',
}) => {
  const { toggleFavoriteItem, isProductFavorite } = useFavorites();

  function sizeMapper(size: string) {
    const key = `Size-${size}`;
    return <TraitItem key={key}>{size}</TraitItem>;
  }
  function colorMapper({ image, text }: ProductDetailsValue) {
    const key = `Color-${text}`;
    const { url } = image || {};
    return (
      <TraitItem key={key}>
        {url && (
          <TraitImage src={url} alt={colorAlt} aria-hidden={true} $rounded />
        )}
        {text}
      </TraitItem>
    );
  }

  return (
    <ProductHeroStyled>
      <Inner>
        <InfoTab>
          <InfoWrapper>
            <InfoContent>
              {product.isNew && <Badge inline text={badgeText || ''} />}

              <ProductDetails large noBottomMargin>
                <div>
                  <span>{product.sku}</span>
                  <span>{product.category || ''}</span>
                </div>

                <Favorite
                  toggleFavorite={toggleFavoriteItem}
                  product={product}
                  isFavorite={isProductFavorite(product.sku)}
                  inFavorites={false}
                />
              </ProductDetails>

              <ProductName>{product.name}</ProductName>
              {product?.price?.value ? (
                <ProductPrice extra={rrpLabel}>
                  {`${product.price.value} ${product.price.currency}`}
                </ProductPrice>
              ) : product?.priceWithVat?.value ? (
                <ProductPrice extra={rrpIncludingVatLabel}>
                  {`${product.priceWithVat.value} ${product.priceWithVat.currency}`}
                </ProductPrice>
              ) : (
                ''
              )}
              {!!product.certifications?.length && (
                <ProductCertificates>
                  {product.certifications.map((item, i) => {
                    return (
                      item.image?.url && (
                        <img
                          key={`Cert-Icon-${i}`}
                          src={item.image.url}
                          alt={item.name || item.text || 'Certification'}
                        />
                      )
                    );
                  })}
                </ProductCertificates>
              )}
              <ProductHeroInfoText>{product.intro || ''}</ProductHeroInfoText>
              {!!product.sizes?.length && (
                <div>
                  {sizesLabel && <TraitLabel>{sizesLabel}</TraitLabel>}
                  <TraitList>{product.sizes.map(sizeMapper)}</TraitList>
                </div>
              )}
              {!!product.colors?.length && (
                <div>
                  {colorsLabel && <TraitLabel>{colorsLabel}</TraitLabel>}
                  <TraitList>{product.colors.map(colorMapper)}</TraitList>
                </div>
              )}
            </InfoContent>
          </InfoWrapper>
        </InfoTab>
        <Carousel>
          <ProductImageCarousel images={images} alt={product.name} />
        </Carousel>
      </Inner>
    </ProductHeroStyled>
  );
};
