import Image from 'next/image';

import { createProductImageSrc } from '@hultafors/eripiowear/helpers';
import { useFavorites } from '@hultafors/eripiowear/hooks';
import { EripiowearProduct } from '@hultafors/eripiowear/types';

import { Badge } from '../badge/badge';
import { Favorite } from '../favorite/favorite';
import { ProductColorsPreview } from '../product-colors-preview/product-colors-preview';
import { ProductDetails } from '../product-details/product-details';

import noImage from './no-image.png';
import {
  FavoriteContainer,
  ImageWrapper,
  ProductImage,
  ProductInfo,
  ProductLink,
  ProductPrice,
  ProductPriceExplanation,
  ProductStyled,
} from './product-card.styled';

export interface ProductCardProps {
  product: EripiowearProduct;
  badgeText?: string;
  msrp: string;
  msrpIncludingVat: string;
  slugs?: string[];
  isSmall?: boolean;
  inFavorites?: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  badgeText,
  msrp,
  msrpIncludingVat,
  inFavorites,
  isSmall,
  slugs,
}) => {
  const { toggleFavoriteItem, isProductFavorite } = useFavorites();

  const href = `/${[
    ...(slugs || ['products', product.parentSlug, product.categorySlug]),
    product.productId,
  ]
    .filter(Boolean)
    .join('/')}`;

  return (
    <ProductStyled $inFavorites={inFavorites} $isSmall={isSmall}>
      <>
        <ProductImage $inFavorites={inFavorites} $isSmall={isSmall}>
          <ImageWrapper>
            {/* {productImage && ( */}
            <Image
              src={createProductImageSrc(product.image?.url, noImage)}
              fill
              style={{ objectFit: 'contain' }}
              sizes="(min-width: 768px)340px, 50vw"
              alt={product.name}
            />
          </ImageWrapper>
          {product.isNew && badgeText && <Badge text={badgeText} />}
        </ProductImage>

        <ProductInfo>
          <ProductDetails>
            <span>{product.sku}</span>
            <span>{product.category}</span>
          </ProductDetails>
          <ProductLink href={href}>{product.name}</ProductLink>

          {product.price ? (
            <ProductPrice>
              {`${product.price} ${product.currency}`}{' '}
              <ProductPriceExplanation>{msrp}</ProductPriceExplanation>
            </ProductPrice>
          ) : (
            product.priceWithVat && (
              <ProductPrice>
                {`${product.priceWithVat} ${product.currency}`}{' '}
                <ProductPriceExplanation>
                  {msrpIncludingVat}
                </ProductPriceExplanation>
              </ProductPrice>
            )
          )}
          <ProductColorsPreview colors={product.colors || []} limit={6} />
        </ProductInfo>
        <FavoriteContainer>
          <Favorite
            toggleFavorite={toggleFavoriteItem}
            product={product}
            isFavorite={isProductFavorite(product.sku)}
            inFavorites={inFavorites}
          />
        </FavoriteContainer>
      </>
    </ProductStyled>
  );
};
